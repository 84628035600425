import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
  return (
    <Layout>
      <main className="page">
        <header className="hero">
          <StaticImage
            src="../assets/images/our-services.jpg"
            alt="office"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
          <div className="hero-container">
            <div className="hero-text">
              <h1>our services</h1>
              <h4>squeeze the data, increase customer satisfaction</h4>
            </div>
          </div>
        </header>
        <section className="page">
          <article>
            <h2>Data Analytics</h2>
            <p>
              Ihr Unternehmen ist eigentlich gut aufgestellt, aber Sie haben das
              Gefühl "aus den Daten können wir mehr rausholen"? Sie wollen ihre
              Kundenbeziehungen ausbauen und vertiefen, wissen aber nicht wo Sie
              den Hebel ansetzen sollen?
            </p>
            <p>Lassen Sie sich von unsere erfahrern Mitarbeiter beraten. </p>
          </article>
          <StaticImage
            src="../assets/images/DataAnalytics.jpg"
            alt="json file example"
            className="about-img"
            placeholder="blurred"
          />
        </section>
        <div></div>
        <section className="page">
          <article>
            <h2>Web Developmemnt</h2>
            <p>
              Ihre Webseiten sind veraltet und irgendwie haben Sie das Gefühl
              den Anschluss zu verpassen? Sie haben die Idee durch Webapps ihre
              Kundenbindung auszubauen und zu verstärken? Erfolgreiche Webseiten
              und Web Apps, individuell zugeschnitten auf die Berdürfnisse ihrer
              Kunden und Nutzer. E-Shop's angepasst auf die Geschäftsprozesse
              ihres Unternehmens. Wir begleiten Sie vom Erstgespräch bis zum
              erfolgreichen Go-Live.
            </p>
            <p>
              Lassen Sie sich von unseren hochmotivierten Kollegen überzeugen.{" "}
            </p>
          </article>
          <StaticImage
            src="../assets/images/WebDev.png"
            alt="waben"
            className="about-img"
            placeholder="blurred"
          />
        </section>
        <section className="page">
          <article>
            <h2>Projektmanagement</h2>
            <p>
              Wir geben unser Bestes für ihren Projekterfolg! Wir planen
              zielgerichtet die Digitalisierung Ihrer Geschäftsprozesse und
              verlieren auch in herausfordernden Phasen nicht das Ziel aus den
              Augen.
            </p>
            <p>
              Wir unterstützen ihr Projektmanagement gerne durch erfahrene
              Kollegen und finden für Sie die optimale Lösung.{" "}
            </p>
          </article>
          <StaticImage
            src="../assets/images/projectmanagement.jpg"
            alt="kanban board"
            className="about-img"
            placeholder="blurred"
          />
        </section>
      </main>
    </Layout>
  )
}

export default Services
